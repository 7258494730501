import { SetupContext } from "@vue/composition-api";

export default function useAuthGroupSelect({ root }: Partial<SetupContext>) {
  const eventPanelItems = [
    {
      value: "alerts",
      name: root?.$tc("authGroup.eventPanelItems.alerts"),
    },
  ];

  const participantItems = [
    {
      value: "participantsList",
      name: root?.$tc("authGroup.participantItems.participantList"),
    },
    {
      value: "participantsManagement",
      name: root?.$tc("authGroup.participantItems.participantManagement"),
    },
    {
      value: "participantsInvoices",
      name: root?.$tc("authGroup.participantItems.participantInvoices"),
    },
  ];

  const lecturersItems = [
    {
      value: "lecturersList",
      name: root?.$tc("authGroup.lecturersItems.lecturersList"),
    },
    {
      value: "lecturersManagement",
      name: root?.$tc("authGroup.lecturersItems.lecturersManagement"),
    },
  ];

  const companiesItems = [
    {
      value: "companiesList",
      name: root?.$tc("authGroup.companiesItems.companiesList"),
    },
    {
      value: "companiesManagement",
      name: root?.$tc("authGroup.companiesItems.companiesManagement"),
    },
  ];

  const abstractsItems = [
    {
      value: "abstractsList",
      name: root?.$tc("authGroup.abstractsItems.abstractsList"),
    },
    {
      value: "abstractsManagement",
      name: root?.$tc("authGroup.abstractsItems.abstractsManagement"),
    },
  ];

  const posterSessionItems = [
    {
      value: "posterSessionList",
      name: root?.$tc("authGroup.posterSessionItems.posterSessionList"),
    },
  ];

  const surveysItems = [
    {
      value: "surveysList",
      name: root?.$tc("authGroup.surveysItems.surveysList"),
    },
  ];

  const accessControl = [
    {
      value: "accessControlView",
      name: root?.$tc("authGroup.accessControl.accessControlView"),
    },
    {
      value: "accessControlManagement",
      name: root?.$tc("authGroup.accessControl.accessControlManagement"),
    },
  ];

  const printItems = [
    {
      value: "printList",
      name: root?.$tc("authGroup.printItems.printList"),
    },
    // {
    //   value: "printManagement",
    //   name: root?.$tc("authGroup.printItems.printManagement"),
    // },
  ];

  const reportsItems = [
    {
      value: "reportsManagement",
      name: root?.$tc("authGroup.reportsItems.reportsManagement"),
    },
  ];

  const infoItems = [
    {
      value: "view",
      name: root?.$tc("authGroup.infoItems.infoView"),
    },
  ];

  const eventConfigItems = [
    {
      value: "eventData",
      name: root?.$tc("authGroup.eventConfigItems.eventData"),
    },
    {
      value: "participantGroups",
      name: root?.$tc("authGroup.eventConfigItems.participantGroups"),
    },
    {
      value: "discounts",
      name: root?.$tc("authGroup.eventConfigItems.discounts"),
    },
    {
      value: "messages",
      name: root?.$tc("authGroup.eventConfigItems.messages"),
    },
  ];

  const registrationModuleItems = [
    {
      value: "moduleData",
      name: root?.$tc("authGroup.registrationModuleItems.moduleData"),
    },
    // {
    //   value: "moduleDegree",
    //   name: root?.$tc("authGroup.registrationModuleItems.moduleDegree"),
    // },
    {
      value: "moduleFee",
      name: root?.$tc("authGroup.registrationModuleItems.moduleFee"),
    },
  ];

  const accomodationModuleItems = [
    {
      value: "moduleData",
      name: root?.$tc("authGroup.accomodationModuleItems.moduleData"),
    },
    {
      value: "moduleHotels",
      name: root?.$tc("authGroup.accomodationModuleItems.moduleHotels"),
    },
  ];

  const additionalServicesModuleItems = [
    {
      value: "moduleData",
      name: root?.$tc("authGroup.additionalServicesModuleItems.moduleData"),
    },
    {
      value: "moduleServices",
      name: root?.$tc("authGroup.additionalServicesModuleItems.moduleServices"),
    },
    {
      value: "moduleForms",
      name: root?.$tc("authGroup.additionalServicesModuleItems.moduleForms"),
    },
  ];

  const abstractsModuleItems = [
    {
      value: "moduleData",
      name: root?.$tc("authGroup.abstractsModuleItems.moduleData"),
    },
    // {
    //   value: "moduleSessions",
    //   name: root?.$tc("authGroup.abstractsModuleItems.moduleSessions"),
    // },
    // {
    //   value: "modulePublications",
    //   name: root?.$tc("authGroup.abstractsModuleItems.modulePublications"),
    // },
  ];

  const partnersModuleItems = [
    {
      value: "view",
      name: root?.$tc("authGroup.partnersModuleItems.view"),
    },
  ];

  const eventProgramModuleItems = [
    {
      value: "moduleProgram",
      name: root?.$tc("authGroup.eventProgramModuleItems.moduleProgram"),
    },
    // {
    //   value: "moduleLocalization",
    //   name: root?.$tc("authGroup.eventProgramModuleItems.moduleLocalization"),
    // },
  ];

  const posterSessionModuleItems = [
    {
      value: "view",
      name: root?.$tc("authGroup.posterSessionModuleItems.view"),
    },
  ];

  const surveysModuleItems = [
    {
      value: "view",
      name: root?.$tc("authGroup.surveyModuleItems.view"),
    },
  ];

  const autoMessagesModuleItems = [
    {
      value: "view",
      name: root?.$tc("authGroup.autoMessagesModuleItems.view"),
    },
  ];

  const companiesModuleItems = [
    {
      value: "moduleData",
      name: root?.$tc("authGroup.companiesModuleItems.moduleData"),
    },
    // {
    //   value: "moduleCodes",
    //   name: root?.$tc("authGroup.companiesModuleItems.moduleCodes"),
    // },
    // {
    //   value: "moduleFees",
    //   name: root?.$tc("authGroup.companiesModuleItems.moduleFees"),
    // },
    // {
    //   value: "moduleHotels",
    //   name: root?.$tc("authGroup.companiesModuleItems.moduleHotels"),
    // },
    // {
    //   value: "moduleAdditionalServices",
    //   name: root?.$tc(
    //     "authGroup.companiesModuleItems.moduleAdditionalServices"
    //   ),
    // },
  ];

  const receptionModuleItems = [
    {
      value: "moduleIdentities",
      name: root?.$tc("authGroup.receptionModuleItems.moduleIdentities"),
    },
    {
      value: "moduleCertificates",
      name: root?.$tc("authGroup.receptionModuleItems.moduleCertificates"),
    },
    // {
    //   value: "moduleTerminals",
    //   name: root?.$tc("authGroup.receptionModuleItems.moduleTerminals"),
    // },
    // {
    //   value: "moduleKiosks",
    //   name: root?.$tc("authGroup.receptionModuleItems.moduleKiosks"),
    // },
  ];

  const siemensItems = [
    {
      value: "siemensReports",
      name: root?.$tc("authGroup.siemenstItems.report"),
    },
  ];

  return {
    eventPanelItems,
    participantItems,
    lecturersItems,
    companiesItems,
    abstractsItems,
    posterSessionItems,
    surveysItems,
    accessControl,
    printItems,
    reportsItems,
    infoItems,
    eventConfigItems,
    registrationModuleItems,
    accomodationModuleItems,
    additionalServicesModuleItems,
    abstractsModuleItems,
    partnersModuleItems,
    eventProgramModuleItems,
    posterSessionModuleItems,
    surveysModuleItems,
    autoMessagesModuleItems,
    companiesModuleItems,
    receptionModuleItems,
    siemensItems,
  };
}
